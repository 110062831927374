import React, { useState } from 'react';
import './index.css';   
import CloseIcon from '../../../../assets/svg/CloseIcon';

const AuditLogRowDetail = ({field, description }: { field: string; description: string }) => {

    return (
        <tr className='audit-row row'>
            <td>{ field }</td>
            <td>{ description }</td>
            <td><div></div></td>
            <td><div></div></td>
        </tr>
    );
};

export default AuditLogRowDetail;