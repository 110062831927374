import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import CustomerAndDeviceInfo from './CustomerAndDeviceInfo/CustomerAndDeviceInfo'
import PolicyInfo from './PolicyInfo/PolicyInfo'
import ActionFooter from '../../components/ActionFooter/ActionFooter'
import Button from '../../components/Button/Button'
import PolicyClaimsTable from './PolicyClaimsTable/PolicyClaimsTable'
import PolicyService from '../../hooks/api/PolicyService'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import PlaceholderContent from '../../components/PlaceholderContent/PlaceholderContent'

import { useAuthState } from '../../contexts'
import { capitalizeFirstLetter } from '../../utils/stringUtils'
import { handleRateLimit } from '../../utils/rateLimitUtil'
import PolicyContext from '../../contexts/Policy/PolicyContext'

import './Policy.css'

const Policy = () => {

  const { isLoading, dataForClaims, getClaimsForPolicy } = useContext(PolicyContext);

  const location = useLocation();
  const [policyData, setPolicyData] = useState<any>(null);
  const [loadingData, setLoadingData] = useState(false)
  const [dataFetched, setDataFetched] = useState(false)

  const { token } = useAuthState();
  

  const policyTableSubheading = ['Claim Type', 'Date Created', 'Last Updated', 'Status', 'Receipt'];

  useEffect(() => {
    const fetchData = async () => {
      const bearerToken = `${capitalizeFirstLetter(token.type)} ${token.token}`;
      setLoadingData(true)
      let data: any = null

      PolicyService.getAPolicy(location.state.imei, bearerToken)
        .then(response => {
          data = response.data;

          if (data.message === undefined) {

            getClaimsForPolicy(location.state.imei, 1)

            data.claims = dataForClaims
            setPolicyData(data)
          } else {
            endLoading()
          }
        })
        .catch(error => {
          handleRateLimit(error)
          endLoading()
        })
    }

    if (!dataFetched) {
      fetchData()
    }
  }, [dataFetched, location.pathname])

  const endLoading = () => {
    setDataFetched(true)
    setLoadingData(false)
  }


  return <>
    {
      (policyData !== null) &&
      <Breadcrumbs lastNodeText={policyData.policy.policyNumber} />
    }

    <div className='policy-container'>
      {
        (policyData !== null) &&
        <>
          <PolicyInfo data={policyData} />
          <hr />
          <CustomerAndDeviceInfo data={policyData} />
        </>
      }

      {
        (isLoading || (loadingData && policyData === null)) &&
        <div className='policy_claim-table-loader'>
          <LoadingSpinner message='Just a moment. Retrieving the policy details.' withContainer={false} />
        </div>
      }

      {
        (!isLoading && !loadingData && policyData === null) &&
        <>
          <Breadcrumbs lastNodeText={'No Policy'} />
          <PlaceholderContent text='No data for the policy could be found.' />
        </>
      }

      {
        (!isLoading && policyData !== null) &&
        <PolicyClaimsTable subHeaders={policyTableSubheading} data={{ claims: dataForClaims, policy: policyData.policy }} />
      }
    </div>

    {
      (policyData !== null) &&
      <ActionFooter
        leading={[
          // <Button text='Archive' styles={actionFooterBtnStyles} onClick={() => { }} />,
          // <Button text='Delete' styles={actionFooterBtnStyles} onClick={() => { }} />
        ]}
        trailing={[
          // <Button text='De-activate' styles={actionFooterBtnStyles} onClick={() => { }} />
        ]}
      />
    }
  </>

}

export const actionFooterBtnStyles = {
  background: '#FFF',
  border: '1px solid #D1D5DB'
}

export default Policy