import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button/Button'
import './ReportFooter.css'

interface IReportFooter {
    disabled: boolean
    onClick: () => void
}

const ReportFooter = ({disabled, onClick}: IReportFooter) => {
    const navigate = useNavigate();
    const goBack = () => navigate(-1)

    const generateReport = () => {
        onClick()
    } 

    return (
        <div className='report-footer'>
            <div className='report-footer__action_btns'>
                <Button classes='' text='Cancel' onClick={goBack} />
                <Button classes='sms-btn generate-btn' text='Generate Report' onClick={generateReport} disabled={disabled}/>
            </div>
        </div>
    )

}


export default ReportFooter