import React, { useCallback, useEffect, useState } from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { useLocation, useParams } from 'react-router-dom';
import { useAuthState } from '../../contexts';
import ClaimService from '../../services/claims/claimService'
import { capitalizeFirstLetter } from '../../utils/stringUtils';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import SnartProtect from '../../assets/smart-protect.png'
import './ClaimReceipt.css'
import { stringToFormattedDate } from '../../utils/dateUtil';
import { calculateClaimCost } from '../../utils/claimUtil';
import { ClaimReceiptRow } from './ClaimReceiptRow';
import ClaimReceiptSectionHeader from './ClaimReceiptSectionHeader';

// Define styles for the PDF
const styles = StyleSheet.create({
  page: { padding: 0 },
  section: { marginBottom: 10, marginLeft: 150, marginTop: 40 },
  title: { fontSize: 8, marginBottom: 10, paddingLeft: 4, marginTop: 2, color: '#bcbebf' },
  text: { fontSize: 12 },
  doc: { height: '100%', width: '100%'},
  smartProtectLogo: { height: 180 , width: 90, position: 'absolute', left: 30},
  sectionHeader: { width: '90%', height: 15, borderRadius: 3, backgroundColor: '#ecf0f2', marginRight: 30, padding: 4 },
  sectionHeaderText: {fontSize: 6, fontWeight: 'black' },
});

// Define the PDF layout
const ClaimReceipt = () => {
  const location = useLocation();
  const { id, imei } = useParams()
  const { token, user } = useAuthState();
  const [claimData, setClaimData] = useState<any>(null);
  const [loadingData, setLoadingData] = useState(false);

  const fetchData = useCallback(async () => {
    const bearerToken = `${capitalizeFirstLetter(token.type)} ${token.token}`;
    setLoadingData(true)
   
    let data: any = null

    if (imei && id) {
      ClaimService.getClaimDetails(imei, +id, bearerToken)
        .then(response => {
          data = response.data
          console.log('Claim Data ', data)
          setClaimData(data)
          setLoadingData(false)
        })
        .catch(error => {
          console.log(error)
          setLoadingData(false)
        })
    }

  }, [])

  const getStatus = (status: string) => {
    switch (status) {
      case 'In-Force':
        return 'Active'

      // TODO: Add additional cases when design is done
      default:
        return 'In-Active'
    }
  }

  const getTodaysDate = () => new Date()

  useEffect(() => {
    fetchData()
  }, [])
  return (
    loadingData
    ?
    <LoadingSpinner message='Loading PDF' />
    :
    <div className='pdf-viewer-container'>
      <PDFViewer className="pdf-viewer" showToolbar >
        <Document style={styles.doc}>
          <Page size="A4" style={styles.page} object-fit='fill'>
            <Image source={SnartProtect} style={styles.smartProtectLogo}></Image>
            <View style={styles.section}>
              <ClaimReceiptSectionHeader heading='Details'/>
            
              <ClaimReceiptRow label='Claim Number' value={ id ?? 'N/A'} valueStyle={{color: '#000'}} rowStyle={{ marginTop: 5}}/>
              <ClaimReceiptRow label='Received by' value={ 'N/A' } valueStyle={{color: '#000'}} />
              <ClaimReceiptRow label='Location' value={ 'SMS Head Office' } valueStyle={{color: '#000'}} />
              <ClaimReceiptRow label='Date Recieved' value={ stringToFormattedDate(claimData?.claim?.dateCreated)} valueStyle={{color: '#000'}} />
            </View>
            
            <ClaimReceiptSectionHeader heading='Customer Details' customStyles={{marginTop: 20, marginLeft: 40, width: '86%'}}/>
   
            <ClaimReceiptRow label='Name' value={ claimData?.customer.fullName ?? 'N/A'} valueStyle={{color: '#000'}} rowStyle={{marginLeft: 40, marginTop: 5}} />
            <ClaimReceiptRow label='Email' value={ claimData?.customer.email ?? 'N/A'} valueStyle={{color: '#000'}} rowStyle={{marginLeft: 40}} />
            <ClaimReceiptRow label='TRN' value={ claimData?.customer.trn ?? 'N/A'} valueStyle={{color: '#000'}} rowStyle={{marginLeft: 40}} />
            <ClaimReceiptRow 
              label='Model' 
              value={ `${claimData?.device.phoneMake} ${claimData?.device?.phoneModel ?? ''}` }
              valueStyle={{color: '#000'}} 
              rowStyle={{marginLeft: 40}} />
            <ClaimReceiptRow label='TRN' value={ claimData?.customer.imei ?? 'N/A'} valueStyle={{color: '#000'}} rowStyle={{marginLeft: 40}} />
            <ClaimReceiptRow label='Phone Cost' value={ claimData?.device.price.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) ?? 'N/A'} valueStyle={{color: '#000'}} rowStyle={{marginLeft: 40}} />

            <ClaimReceiptSectionHeader heading='Policy Details' customStyles={{marginTop: 20, marginLeft: 40, width: '86%'}}/>
            <ClaimReceiptRow label='Policy Number' value={ claimData?.policy.policyNumber ?? 'N/A'} valueStyle={{color: '#000'}} rowStyle={{ marginLeft: 40, marginTop: 5 }} />
            <ClaimReceiptRow label='Policy Type' value={ claimData?.policy.plan ?? 'N/A'} valueStyle={{color: '#000'}} rowStyle={{marginLeft: 40}} />
            <ClaimReceiptRow label='Current Status' value={ getStatus(claimData?.policy.status).toUpperCase() ?? 'N/A'} valueStyle={{color: '#719748'}} rowStyle={{marginLeft: 40}} />
            <ClaimReceiptRow label='Effective Date' value={ stringToFormattedDate(claimData?.policy.effectDate) ?? 'N/A'} valueStyle={{color: '#000'}} rowStyle={{marginLeft: 40}} />
            <ClaimReceiptRow label='Expiry Date' value={ stringToFormattedDate(claimData?.policy.endDate) ?? 'N/A'} valueStyle={{color: '#000'}} rowStyle={{marginLeft: 40}} />
            <ClaimReceiptRow label='Premium' value={ claimData?.policy.premium.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) ?? 'N/A'} valueStyle={{color: '#000'}} rowStyle={{marginLeft: 40}} />
            <ClaimReceiptRow label='Policy Period' value={ claimData?.policy.period ?? 'N/A'} valueStyle={{color: '#000'}} rowStyle={{marginLeft: 40}} />

            <ClaimReceiptSectionHeader heading={`Claim Details (${capitalizeFirstLetter(claimData?.claim.type)})`} customStyles={{marginTop: 20, marginLeft: 40, width: '86%'}}/>
            <ClaimReceiptRow label='Total Claims Cost' value={ claimData ? calculateClaimCost(claimData?.policy.premium, claimData?.policy.period).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '$0.00' } valueStyle={{color: '#000'}} rowStyle={{marginLeft: 40, marginTop: 5}} />
            <ClaimReceiptRow label='Claims Status' value={ claimData?.claim.status.toUpperCase() ?? 'N/A' } valueStyle={{color: '#bb2322'}} rowStyle={{marginLeft: 40}} />
            <ClaimReceiptRow label='Approve/Decline Rate' value={ 'N/A' } valueStyle={{color: '#000'}} rowStyle={{marginLeft: 40}} />

            <View style={{width: '86%', marginLeft: 40, marginTop: 20, flexDirection: 'row', justifyContent: 'space-between'}}>
              <ClaimReceiptSectionHeader heading='Customer Signature' customStyles={{width: '60%', marginRight: 0, marginTop: 20}}/>
              <ClaimReceiptSectionHeader heading='Date' customStyles={{width: '30%', marginRight: 0, marginTop: 20, marginLeft: 40}}/>
              
              {/* <View style={{width: '30%', height: 15, borderRadius: 3, backgroundColor: '#ecf0f2', marginLeft: 40, padding: 4, marginTop: 20}}>
                <Text style={{fontSize: 6, fontWeight: 'black' }}>Date</Text>
              </View> */}
            </View>

            <View style={{width: '86%', marginLeft: 40, marginTop: 20, flexDirection: 'row', justifyContent: 'space-between'}}>
              <View style={{width: '60%', height: 15,  backgroundColor: 'transparent', padding: 4, marginTop: 20, borderBottom: 1, borderBottomColor: '#000'}}>
              </View>
              <View style={{width: '30%', height: 30, backgroundColor: 'transparent', marginLeft: 40, padding: 4, marginTop: 20, justifyContent: 'flex-end', flexDirection: 'row'}}>
                <Text style={{fontSize: 10, fontWeight: 'black' }}>{ stringToFormattedDate(getTodaysDate().toString()) }</Text>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </div>
  )
}

export default ClaimReceipt;
