export const ToasterId = {
  CreateUserSuccess: 'create_user_success',
  CreateUserError: 'create_user_error',
  CreateClaimSuccess: 'create_claim_success',
  CreateClaimError: 'create_claim_error',
  UpdateClaimSuccess: 'update_claim_success',
  CreateCommentSuccess: 'create_comment_success',
  CreateCommentError: 'create_comment_error',
  UpdateClaimError: 'update_claim_error',
  GenerateReportSuccess: 'generate_report_success',
  GenerateReportError: 'generate_report_error',

  RateLimit: 'rate_limit'
}