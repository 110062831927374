import axios from "axios";
import { network } from "../../services";

const getAuditLogs = async (page: number, limit: number,  sortBy: string, bearerToken: string, order: string) => {
    const response = await axios.get(`${network.baseApi}/audit?page=${page}&limit=${limit}&sortBy=${sortBy}&order=${order}`, { headers: { Authorization: bearerToken } })
    return response
}

const AuditService = {
    getAuditLogs,
}

export default AuditService