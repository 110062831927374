import React from 'react';

interface ReportsIcon {
    fillColor: string;
}

export function ReportsIcon({ fillColor }: ReportsIcon): JSX.Element {
    return (
        <div>
            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_3171_56945)">
                    <path d="M4.01733 11.4456C4.01733 11.702 4.21172 11.9093 4.45214 11.9093H6.02765C6.26807 11.9093 6.46246 11.702 6.46246 11.4456C6.46246 11.1892 6.26807 10.9819 6.02765 10.9819H4.45214C4.21683 10.9819 4.01733 11.1892 4.01733 11.4456Z" fill={fillColor} />
                    <path d="M4.01733 9.18195C4.01733 9.43834 4.21172 9.64563 4.45214 9.64563H6.88191C7.12233 9.64563 7.31672 9.43834 7.31672 9.18195C7.31672 8.92556 7.12233 8.71826 6.88191 8.71826H4.45214C4.21683 8.71826 4.01733 8.92556 4.01733 9.18195Z" fill={fillColor} />
                    <path d="M4.01733 6.83212C4.01733 7.08851 4.21172 7.29581 4.45214 7.29581H9.42934C9.66976 7.29581 9.86415 7.08851 9.86415 6.83212C9.86415 6.57573 9.66976 6.36844 9.42934 6.36844H4.45214C4.21683 6.36844 4.01733 6.57573 4.01733 6.83212Z" fill={fillColor} />
                    <path d="M2.76282 2.68207V13.2454C2.76282 13.9382 3.32449 14.4999 4.01734 14.4999H10.808C11.5009 14.4999 12.0625 13.9382 12.0625 13.2454V4.81017C12.0625 4.5487 11.9808 4.29376 11.8289 4.081L10.3088 1.9529C10.0733 1.62321 9.6931 1.42755 9.28795 1.42755H4.01734C3.32449 1.42755 2.76282 1.98922 2.76282 2.68207Z" stroke="white" />
                    <path d="M9.29895 1.55042V3.81398C9.29895 4.23069 9.63676 4.5685 10.0535 4.5685H11.9398" stroke="white" />
                </g>
                <defs>
                    <clipPath id="clip0_3171_56945">
                        <rect width="14.7419" height="14.7419" fill={fillColor} transform="translate(0 0.629028)" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    )
}
