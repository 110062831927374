import React, { CSSProperties, ReactNode } from 'react'

import './Button.css';

interface IButton {
  text: string | ReactNode;
  classes?: string;
  styles?: CSSProperties;
  onClick: any;
  disabled?: boolean
}

const Button = ({ text, classes = "", styles = {}, onClick, disabled }: IButton) => {
  return (
    <button
      className={`${classes} ${disabled ? "disabled-btn" : ""}`}
      style={styles}
      onClick={onClick}
      disabled={disabled !== undefined ? disabled : false}
    >
      {text}
    </button>
  )
}


export default Button