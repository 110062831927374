// Order of below is very important, DO NOT CHANGE/REARRANGE
export const deviceIssues: any[] = [
  { id: 1, name: 'Not Charging', apiKey: 'not_charging' },
  { id: 2, name: 'Device Rebooting', apiKey: 'device_rebooting' },
  { id: 3, name: 'Screen Appears Blank', apiKey: 'screen_blank' },
  { id: 4, name: 'Device not coming on', apiKey: 'not_powering_on' },
  { id: 5, name: 'Screen White', apiKey: 'screen_white' },
  { id: 6, name: 'Screen Broken', apiKey: 'screen_broken' },
  { id: 7, name: 'Not Hearing the person', apiKey: 'not_hearing_person' },
  { id: 8, name: 'People can\'t hear when I speak', apiKey: 'people_cant_hear_when_i_speak' },
  { id: 9, name: 'Not able to hear phone ring', apiKey: 'not_able_to_hear_phone_ring' },
  { id: 10, name: 'Not able to Play multimedia', apiKey: 'not_able_to_play_multimedia' },
  { id: 11, name: 'Unable to hear from headphone jack', apiKey: 'unable_to_hear_from_headphone_jack' },
  { id: 12, name: 'Unable to pair bluethooth', apiKey: 'unable_to_pair_bluetooth' },
  { id: 13, name: 'Device freezes', apiKey: 'device_freezes' },
  { id: 14, name: 'Unable to access camera', apiKey: 'unable_to_access_camera' },
  { id: 15, name: 'Camera Reboots when taking pictures', apiKey: 'camera_reboots_when_taking_picture' },
  { id: 16, name: 'Camera Not taking pictures', apiKey: 'camera_not_taking_pictures' },
  { id: 17, name: 'Camera Giving trouble', apiKey: 'camera_giving_trouble' },
  { id: 18, name: 'Infinite Boot', apiKey: 'infinite_boot' },
  { id: 19, name: 'Digitizer Broken', apiKey: 'digitizer_broken' },
  { id: 20, name: 'Device Performance Slow', apiKey: 'device_performance_slow' },
  { id: 21, name: 'Device Shuts Down', apiKey: 'device_shuts_down' },
  { id: 22, name: 'Not Reading SIM Card', apiKey: 'not_reading_sim_card' },
  { id: 23, name: 'WARRANTY ASSESMENT', apiKey: 'warrant_assessment' },
  { id: 24, name: 'Touch isn\'t working', apiKey: 'touch_is_not_working' },
  { id: 25, name: 'Screen Flickering', apiKey: 'screen_flickering' },
  { id: 26, name: 'Device Shuts Down Intermittently', apiKey: 'device_shuts_down_intermittently' },
  { id: 27, name: 'Not Reading Memory Card', apiKey: 'not_reading_memory_card' }
]

// Order of below is very important, DO NOT CHANGE/REARRANGE
export const deviceConditions: any[] = [
  { id: 1, name: 'Device Has Power', apiKey: 'device_has_power' },
  { id: 2, name: 'Device Is Charging', apiKey: 'device_charging' },
  { id: 3, name: 'Device Is Rebooting', apiKey: 'device_rebooting' },
  { id: 4, name: 'Volume Buttons Work', apiKey: 'volume_buttons_work' },
  { id: 5, name: 'Camera Is Working', apiKey: 'camera_working' },
  { id: 6, name: 'Enter Button is Working', apiKey: 'enter_button_working' },
  { id: 7, name: 'Option Button is working', apiKey: 'option_button_working' },
  { id: 8, name: 'Speakers are working', apiKey: 'speakers_working' },
  { id: 9, name: 'Touch screen is operational', apiKey: 'touch_screen_working' },
  { id: 10, name: 'Lens of device scratched', apiKey: 'lens_scratched' },
  { id: 11, name: 'Top of device scratched', apiKey: 'top_of_device_scratched' },
  { id: 12, name: 'Device boots ups', apiKey: 'device_boots_up' },
  { id: 13, name: 'Scroll button is working', apiKey: 'scroll_button_working' },
  { id: 14, name: 'Return button is working', apiKey: 'return_button_working' },
  { id: 15, name: 'Keyboard buttons working', apiKey: 'keyboard_button_working' },
  { id: 16, name: 'Ability to play media', apiKey: 'able_to_play_media' },
  { id: 17, name: 'LCD is operational', apiKey: 'lcd_operational' },
  { id: 18, name: 'Side rails scratched', apiKey: 'side_rails_scratched' },
  { id: 19, name: 'Back of device scratched', apiKey: 'back_of_device_scratched' },
  { id: 20, name: 'Lower end of device scratched', apiKey: 'lower_end_of_device_scratched' }
];

/**
 * Return the name of an issue matching the apiKey passed for that issue.
 * @param key Api key for a device issue used on the backend
 * @returns string
 */
export const getIssueText = (key: string) => {
  return deviceIssues.find(issue => issue.apiKey.toLowerCase() === key.toLowerCase()).name
}

/**
 * eturn the name of a condition matching the apiKey passed for that condition.
 * @param key Api key for a device condition on the backend
 * @returns string
 */
export const getConditionText = (key: string) => {
  return deviceConditions.find(issue => issue.apiKey.toLowerCase() === key.toLowerCase()).name
}

export const sortOptions = {
  type: 'Type',
  status: 'Status',
  policyNumber: 'Policy',
  date: 'Date',
  fname: 'First Name',
  lname: 'Last Name',
  claimNumber: 'Claim Number'
}

export const claimTableHeadings = ['Date Reported', 'Claim #', 'Policy Number', 'First Name', 'Last Name', 'Type', 'Status', 'Action']

export const calculateClaimCost = (premium: number, period: string): number => {
  const normalizedPeriod = period.trim().toLowerCase()
  if (normalizedPeriod === "1 year") {
      return premium
  } else if (normalizedPeriod === "2 year") {
      return premium * 2
  } else {
      return 0
  }
}
