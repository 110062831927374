import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import DatePicker from 'react-datepicker'


import './ReportArea.css'
import Dropdown, { IDropdown } from '../../../components/Dropdown/Dropdown';

interface IReportArea {
    toDate: Date
    setToDate: (date: Date) => void
    fromDate: Date
    setFromDate: (date: Date) => void
    reportType: string
    setReportType: (report: string) => void
    dateErrors: {startDate?: string, endDate?: string}
}

const ReportArea = ({toDate, setToDate, fromDate, setFromDate, reportType, setReportType, dateErrors}: IReportArea) => {
    const location = useLocation();

    const [showReportOptions, setShowReportOptions] = useState(false)

    const reportText = () => {
        if (reportType === 'claim') {
            return 'Claim Creation'
        } else if (reportType === 'policy') {
            return 'Policy Creation'
        }

        return 'Select report type'
    }

    const reportOptions: IDropdown = {
        text: reportText(),
        toggleVisibility: () => setShowReportOptions(!showReportOptions),
        visible: showReportOptions,
        options: [
            {
              id: 1,
              text: 'Claim Creation',
              selected: reportType === 'claim',
              onClick: () => setReportType('claim')
            }
        ]
    }
    
    return (
        <div className='report-area'>
            <p className='field-label'>Report Type</p>
            <Dropdown { ...reportOptions } className='report-area-dropdown'/>

            <div className='range-picker'>
                <div className='start-date__container'>
                    <p className='field-label'>Start Date</p>
                    <DatePicker
                        data-testid="start-date-picker"
                        className={`report-date ${dateErrors.startDate ? 'error' : ''}`}
                        selected={fromDate}
                        onChange={(date: Date) => setFromDate(date)}
                        dateFormat='MMMM d, yyyy'
                    />
                    {dateErrors.startDate && <p className='error-message'>{dateErrors.startDate}</p>}
                </div>

                <div className='start-date__container'>
                    <p className='field-label'>End Date</p>
                    <DatePicker
                        data-testid="end-date-picker"
                        className={`report-date ${dateErrors.endDate ? 'error' : ''}`}
                        selected={toDate}
                        onChange={(date: Date) => setToDate(date)}
                        minDate={fromDate}
                        dateFormat='MMMM d, yyyy'
                    />
                    {dateErrors.endDate && <p className='error-message'>{dateErrors.endDate}</p>}
                </div>
            </div>
        </div>
    )

}


export default ReportArea