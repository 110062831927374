import { View, Text, StyleSheet } from "@react-pdf/renderer";
import React from "react";

const styles = StyleSheet.create({
    title: { fontSize: 10, marginBottom: 10, paddingLeft: 4, marginTop: 2, color: "#bcbebf" },
    rowView: { display: "flex", flexDirection: "row", justifyContent: "space-between", marginRight: 50},
    });

export const ClaimReceiptRow = (
    { label, value, valueStyle, rowStyle } : { label: string; value: string; valueStyle?: {}, rowStyle?: {} }
) => (
    <View style={ [styles.rowView, rowStyle ? rowStyle : {}] }>
      <Text style={ styles.title }>{ label }</Text>
      <Text style={[styles.title, valueStyle ? valueStyle : {}]}>{ value }</Text>
    </View>
)