import React, { ReactNode } from 'react'

import ContentInfo from '../ContentInfo/ContentInfo';
import Dropdown, { IDropdown } from '../Dropdown/Dropdown';
import { routes } from '../../routes/routes'

import { Link, useLocation } from 'react-router-dom';

import './ContentControls.css';


interface IContentControls {
  contentInfoLabel: ReactNode;
  contentInfoArray: ReactNode[];
  itemsPerPageProps: IDropdown;
  sortProps: IDropdown;
  filterProps: IDropdown;
  orderControl?: boolean
  handleOrderControl?: () => void
  order?: string
}



const ContentControls = ({ 
  contentInfoLabel,
  contentInfoArray,
  itemsPerPageProps,
  sortProps,
  filterProps,
  orderControl = false,
  handleOrderControl,
  order
}: IContentControls) => {

  const location = useLocation();

  return (
    <div className='content-ctrl-container'>
      <div className='content-controls_content-info'>
        {contentInfoLabel}{location.pathname.includes('/claims') && 
          <div className='content-controls_content-info2'>
            <div>{contentInfoArray[0]}</div>
            <div className='divider'></div>
            <div>{contentInfoArray[1]}</div>
          </div> }
      </div>
      
      

      <div className='controls'>
        <Dropdown {...itemsPerPageProps} data-testid="1" />
        <Dropdown {...sortProps} data-testid="2" toggle={ orderControl } handleToggle={handleOrderControl} order={order}/>
        {
          filterProps.options && filterProps.options?.length > 0
          ?
          <Dropdown {...filterProps} data-testid="3" />
          : null
        }
      </div>
    </div>
  )
}

export default ContentControls