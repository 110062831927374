import Policies from "../components/Content/Policies/Policies";
import Dashboard from "../components/Content/Dashboard/Dashboard";
import Customers from "../components/Content/Customer/Customers";
import Policy from "../pages/Policy/Policy";
import Users from "../components/Content/Users/Users";
import Claim from "../pages/Claim/Claim";
import EditPolicy from "../pages/EditPolicy/EditPolicy";
import AllClaims from "../pages/AllClaims/AllClaims"
import Reports from "../pages/Reports/Reports";
import AuditLog from "../pages/AuditLog/AuditLog";

interface IRoute {
    index: number;
    path: string;
    /**
     * The array of roles authorized to access the route. An empty array indicates that anyone
     * can access the route. An array with the only the value `'None'` indicates that no one can
     * access the route and is only meant for testing purposes or temporarily disabling a route.
     */
    authorizedRoles: string[];
    component: JSX.Element;
}

export const routes: IRoute[] = [
    {
        index: 1,
        path: "/",
        authorizedRoles: ['None'],
        component: <Dashboard />
    },
    {
        index: 2,
        path: "/policies",
        authorizedRoles: [],
        component: <Policies />
    },
    {
        index: 2.1,
        path: "/policies/:id",
        authorizedRoles: [],
        component: <Policy />
    },
    {
        index: 2.2,
        path: 'policies/:policyNmber/claim',
        authorizedRoles: [],
        component: <Claim />
    },
    {
        index: 2.3,
        path: '/policies/:id/edit',
        authorizedRoles: [],
        component: <EditPolicy />
    },
    // {
    //     index: 3,
    //     path: "/claims",
    //     authorizedRoles: ['None'],
    //     <AllClaims />
    // },
    {
        index: 4,
        path: "/customers",
        authorizedRoles: ['None'],
        component: <Customers />
    },
    {
        index: 5,
        path: "/users",
        authorizedRoles: [
            "Administrator",
            "SMSJ Super Admin"
        ],
        component: <Users />
    }, 
    {
        index: 6,
        path: "/claims",
        authorizedRoles: [],
        component: <AllClaims />
    },
    {
        index: 6.1,
        path: "/claims/claim",
        authorizedRoles: [],
        component: <Claim />
    },
    {
        index: 7,
        path: "/audits",
        authorizedRoles: [],
        component: <AuditLog />
    },
    {
        index: 8,
        path: "/reports",
        authorizedRoles: [],
        component: <Reports />
    }
]

