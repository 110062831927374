import { useState } from 'react';

import { ReactComponent as ColumnIcon } from "../../../../assets/svg/table/column-sort.svg";

import "../Policies/Table/Table.css"
import "./Table.css"
import { useLocation, useNavigate } from 'react-router-dom';
import { formatAuditDate } from '../../../utils/dateUtil';

interface InputField {
  headers?: any;
  data?: any;
  toggle: (data?: any) => void
}

export function Table({ headers, data, toggle}: InputField): JSX.Element {

  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <table className="table">
      <thead className="table-head">
        <tr>
          {/* <th className="table-header" scope="col" key={'checkbox'}>
            <Checkbox checked={false} onClick={() => {}} />
          </th> */}
          {headers?.map((header: any) => {
            return (
              <th className="table-header" scope="col" key={header}>
                <p className="table-head-title">
                  {header} 
                  {/* {(typeof header === 'string') && <ColumnIcon />} */}
                </p>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {data.map((datum: any, index: number) => {
          return (
            <tr className={`table-row ${(checkedItems.includes(`${index}`)) ? 'checked' : ''}`} key={index} onClick={() => { }}>
              {/* <td>
                <Checkbox checked={checkedItems.includes(`${index}`)} onClick={() => addOrToggleCheckedItems(`${index}`)} />
              </td> */}
              <td className="table-data">
                <p className="table-data-top">{datum.performed_by}</p>
                {/* <p className="table-data-bottom">{stringToFormattedDate(datum.dateCreated)}</p> */}
              </td>
              <td>
                <p className="table-data-top__w-400">{datum.performed_on}</p>
              </td>
              <td>
                <p className="table-data-top__w-400">{formatAuditDate(datum.event_time) ?? 'N/A'}</p>
              </td>
              <td>
                  <div className="table-data-top__w-400" style={{paddingRight: '6px'}}>
                    <button 
                      onClick={() => toggle(datum)}
                      className="view-log-btn">
                        View Details
                    </button>
                  </div>
              </td>

            </tr>
          );
        })}
      </tbody>
    </table>
  )
}
