import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router'

import ActionFooter from '../../components/ActionFooter/ActionFooter'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import PlaceholderContent from '../../components/PlaceholderContent/PlaceholderContent'
import CustomerAndDeviceInfo from '../Policy/CustomerAndDeviceInfo/CustomerAndDeviceInfo'
import ClaimInfo from './ClaimInfo/ClaimInfo'
import ClaimTheftContent from './ClaimTheftContent/ClaimTheftContent'
import { useAuthState } from '../../contexts'
import { capitalizeFirstLetter, convertToBase64 } from '../../utils/stringUtils'

import './Claim.css'
import ClaimLiquidContent from './ClaimLiquidContent/ClaimLiquidContent'
import ClaimPhysicalContent from './ClaimPhysicalContent/ClaimPhysicalContent'
import ClaimService from '../../services/claims/claimService'
import toast from 'react-hot-toast'
import AppToaster, { ToastType } from '../../components/AppToaster/AppToaster'
import { ToasterId } from '../../enums/ToasterIds'
import Comments from '../../components/Comments/Comments'

const Claim = () => {
  const location = useLocation();
  const { token, user } = useAuthState();
  const [claimData, setClaimData] = useState<any>(null);
  const [retrievedComments, setComments] = useState<any>(null);
  const [loadingData, setLoadingData] = useState(false);

  const fetchData = useCallback(async () => {
    const bearerToken = `${capitalizeFirstLetter(token.type)} ${token.token}`;
    setLoadingData(true)
   
    let data: any = null

    ClaimService.getClaimDetails(location.state.imei, location.state.claimId, bearerToken)
      .then(response => {
        data = response.data
        setClaimData(data)
        endLoading()
      })
      .catch(error => {
        console.log(error)
        endLoading()
      })

    ClaimService.getComments(location.state.claimId, bearerToken)
      .then(response => {
        data = response.data
        setComments(data.commentsData)
        endLoading()
      })
      .catch(error => {
        console.log(error)
        endLoading()
      })
  }, [])

  const addComment = useCallback(async (content: string, documents: any) => {
    const bearerToken = `${capitalizeFirstLetter(token.type)} ${token.token}`;
    setLoadingData(true)

    ClaimService.createComment(
      location.state.claimId.toString(), 
      claimData.claim.status, 
      `${user?.firstName} ${user?.lastName}`,
      content,
      documents,
      bearerToken,
    ).then(response => {
      toast.custom(<AppToaster type={ ToastType.SUCCESS } id={ ToasterId.CreateCommentSuccess } message={`Comment succesfully created`}></AppToaster>, { id: ToasterId.CreateCommentSuccess, duration: 5000 })
      fetchData()
      endLoading()
    })
    .catch(error => {
      console.log(error)
      toast.custom(<AppToaster type={ ToastType.ERROR } id={ ToasterId.CreateCommentError } message={`Comment failed to be created`}></AppToaster>, { id: ToasterId.CreateCommentError, duration: 5000 })
      endLoading()
    })
  }, [claimData])

  useEffect(() => {

    fetchData()
  }, [])

  const endLoading = () => {
    setLoadingData(false)
  }

  const renderDetails = (data: any) => {
    switch (data.claim.type.toLowerCase()) {
      case 'theft':
        return <ClaimTheftContent data={claimData} />
      case 'physical damage':
        return <ClaimPhysicalContent data={claimData} />
      case 'liquid damage':
        return <ClaimLiquidContent data={claimData} />
    }
  }

  return <>
    {
      (loadingData && claimData === null) &&
      <LoadingSpinner message='Just a moment. Retrieving the claim details.' />
    }

    {
      (!loadingData && claimData === null) &&
      <>
        <Breadcrumbs lastNodeText={'No Claim'} />
        <PlaceholderContent text='No data for the claim could be found.' />
      </>
    }

    {
      (claimData !== null) && <div>
        <Breadcrumbs lastNodeText={'Claim'} />
        <div className='policy-container'>
          <ClaimInfo data={claimData} fetchClaims={ fetchData } />
          <hr />
          <CustomerAndDeviceInfo data={claimData} />

          { renderDetails(claimData) }

          <Comments data={ claimData } commentsData={ retrievedComments } addComment={ addComment }/>

          <ActionFooter
            leading={[
              // <Button text='Archive' styles={actionFooterBtnStyles} onClick={() => { }} />
            ]}
            trailing={[
              // <Button text='Delete' styles={actionFooterBtnStyles} onClick={() => { }} />
            ]}
          />
        </div>
      </div>
    }
  </>
}

export default Claim