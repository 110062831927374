interface AuditsIcon {
    fillColor: string;
}

export function AuditsIcon({ fillColor }: AuditsIcon): JSX.Element {
    return (
        <div>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.8845 15.002L13.8981 12.8837C14.3591 12.0522 14.6574 11.3582 14.6574 10.3894C14.6574 8.08302 12.9015 6.21048 10.7388 6.21048C8.57616 6.21048 6.82025 8.08302 6.82025 10.3894C6.82025 12.6957 8.58294 14.7924 10.7388 14.5682C11.6202 14.4742 12.4134 14.308 13.0778 13.7585L15.0642 15.8768C15.254 16.0793 15.6269 16.0142 15.8235 15.8118C16.0133 15.6021 16.0744 15.2045 15.8845 15.002ZM7.97278 10.3894C7.97278 8.7554 9.21344 7.43233 10.7456 7.43233C12.2778 7.43233 13.5185 8.7554 13.5185 10.3894C13.5185 12.0233 12.2778 13.3464 10.7456 13.3464C9.21344 13.3464 7.97278 12.0233 7.97278 10.3894Z" fill={ fillColor }/>
                <path d="M12.1829 1.40983C12.1829 0.636231 11.5931 0 10.8609 0H1.32202C0.589822 0.0072299 0 0.636231 0 1.40983V7.1576V7.38172V14.5899C0 15.3635 0.589822 15.9998 1.32202 15.9998H2.28471L2.29149 15.9925C2.32539 15.9998 2.35929 15.9998 2.39997 15.9998H7.13888C7.45752 15.9998 7.71515 15.725 7.71515 15.3852C7.71515 15.0454 7.45752 14.7707 7.13888 14.7707H3.25419H2.39997H1.81692C1.45083 14.7707 1.15931 14.4526 1.15931 14.0694V7.38895V7.16483V1.93761C1.15931 1.5472 1.45761 1.23631 1.81692 1.23631H10.3727C10.7388 1.23631 11.0304 1.55443 11.0304 1.93761V4.4247C11.0304 4.7645 11.288 5.03924 11.6066 5.03924C11.9253 5.03924 12.1829 4.7645 12.1829 4.4247V1.40983Z" fill={ fillColor }/>
                <path d="M2.22369 3.81739C2.22369 4.1572 2.48132 4.43193 2.79996 4.43193H9.39647C9.71511 4.43193 9.97274 4.1572 9.97274 3.81739C9.97274 3.47759 9.71511 3.20285 9.39647 3.20285H2.79996C2.4881 3.20285 2.22369 3.47759 2.22369 3.81739Z" fill={ fillColor }/>
                <path d="M2.22369 7.54801C2.22369 7.88782 2.48132 8.16255 2.79996 8.16255H6.02025C6.33889 8.16255 6.59651 7.88782 6.59651 7.54801C6.59651 7.20821 6.33889 6.93347 6.02025 6.93347H2.79996C2.4881 6.93347 2.22369 7.20821 2.22369 7.54801Z" fill={ fillColor }/>
                <path d="M2.22369 11.1774C2.22369 11.5172 2.48132 11.7919 2.79996 11.7919H4.88806C5.2067 11.7919 5.46433 11.5172 5.46433 11.1774C5.46433 10.8376 5.2067 10.5629 4.88806 10.5629H2.79996C2.4881 10.5629 2.22369 10.8376 2.22369 11.1774Z" fill={ fillColor }/>
            </svg>
        </div>
    )
}


