export interface IAuditData {
  data: {
    meta: Meta,
    auditData: any[]
  },
  isLoading: boolean,
  sortBy: string;
  setSortBy: (sortBy: string) => void,
  filterBy: string | undefined,
  setFilterBy: (filterBy: string) => void,
  itemsPerPage: number,
  order: string,
  setSortOrderBy: (order: string) => void,
  setItemsPerPage: (id: number) => void,
  getAuditLogs: (page: number) => any,
}

export interface Meta {
  total: number,
  perPage: number,
  currentPage: number,
  lastPage: number,
  firstPageUrl: string,
  lastPageUrl: string,
  nextPageUrl: string,
  prevPageUrl: string,
  path: string,
}

export const AUDIT_ACTION_TYPES = {
  SET_AUDIT_DATA: 'SET_AUDIT_DATA',
  SET_AUDIT_SORT_BY: 'SET_AUDIT_SORT_BY',
  SET_AUDIT_FILTER_BY: 'SSET_AUDIT_FILTER_BY',
  SET_AUDIT_ITEMS_PER_PAGE: 'SET_AUDIT_ITEMS_PER_PAGE',
  SET_AUDIT_ORDER_TYPE:'SET_AUDIT_ORDER_TYPE'
}