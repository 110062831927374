import { CSVLink } from "react-csv"
import './GeneratedReport.css'

interface IGeneratedReport {
    data: any
    loading: boolean
}

const GeneratedReport = ({data, loading}: IGeneratedReport) => {
    return (
        <div className='download-area'>
            {
                !data || data.length <= 0
                ?
                <div className=''>
                    <p>Your report will show here once generated</p>
                </div>
                :
                <CSVLink 
                    data={data} 
                    filename={`claims-report-${new Date().toISOString().split('T')[0]}.csv`}
                    enclosingCharacter={""}
                    className='downlaod-csv__btn'
                >
                    Download Report
                </CSVLink>
            }
        </div>
    )

}


export default GeneratedReport