import { useCallback, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'

import { useAuthState } from '../../contexts'

import './Reports.css'
import ContentHeader from '../../components/ContentHeader/ContentHeader'
import { capitalizeFirstLetter } from '../../utils/stringUtils'
import ReportArea from './ReportArea/ReportArea'
import ReportFooter from './ReportFooter/ReportFooter'
import ClaimService from '../../hooks/api/ClaimService'
import { handleRateLimit } from '../../utils/rateLimitUtil'
import GeneratedReport from './GeneratedReport/GeneratedReport'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import toast from 'react-hot-toast'
import AppToaster, { ToastType } from '../../components/AppToaster/AppToaster'
import { ToasterId } from '../../enums/ToasterIds'

const Reports = () => {
    const location = useLocation();
    const { token, user } = useAuthState();

    const title = capitalizeFirstLetter(location.pathname.split('/').pop() || '');

    const [startDate, setStartDate] = useState<Date>(new Date())
    const [endtDate, setEndDate] = useState<Date>(new Date())
    const [reportType, setReportType] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [csvData, setCsvData] = useState<any[]>([])
    const [dateErrors, setDateErrors] = useState<{startDate?: string, endDate?: string}>({});

    const contentHeaderActions: JSX.Element[] = [
        // <Button classes='p-btn' text='View Archive' onClick={() => { }} />,
        // <Button classes='p-btn sms-btn' text='Generate Report' onClick={() => { }} />
    ]
    const contentSubHeader = 'Select a Report Type and date range from the date pickers to generate a Report.'

    const validateDates = useCallback(() => {
        const errors: {startDate?: string, endDate?: string} = {};
        const today = new Date();

        if (!startDate) {
            errors.startDate = "Start date is required";
        }
        
        if (!endtDate) {
            errors.endDate = "End date is required";
        }
    
        if (startDate && endtDate && startDate > endtDate) {
            errors.startDate = "Start date must be before end date";
            errors.endDate = "End date must be after start date";
        }
    
        if (startDate && startDate > today) {
            errors.startDate = "Start date cannot be in the future";
        }
    
        if (endtDate && endtDate > today) {
            errors.endDate = "End date cannot be in the future";
        }

        setDateErrors(errors);
        return errors;
    }, [startDate, endtDate]);

    useEffect(() => {
        validateDates();
    }, [startDate, endtDate, validateDates]);

    const showSuccessToast = () => {
        toast.custom(<AppToaster type={ToastType.SUCCESS} id={ToasterId.GenerateReportSuccess} message={`Report generated successfully`}></AppToaster>, { id: ToasterId.GenerateReportSuccess })
    }

    const showErrorToast = (message?: string) => {
        toast.custom(<AppToaster type={ToastType.ERROR} id={ToasterId.GenerateReportError} message={`${message ? message : 'Report generation unsuccessful'}`}></AppToaster>, { id: ToasterId.GenerateReportError })
    }

    const generateReportData = useCallback(() => {
        setIsLoading(true)
        const bearerToken = `${capitalizeFirstLetter(token.type)} ${token.token}`
        const generateByUser = `${user?.firstName} ${user?.lastName}`

        const errors = validateDates()
        if (Object.keys(errors).length > 0) {
            setIsLoading(false)
            return
        }

        if (startDate && endtDate) {
            ClaimService.generateReportData(startDate, endtDate, generateByUser, bearerToken).then(response => {
                // TODO: handle no claims found for range
                if (response.data?.code == 404) {
                    setIsLoading(false)
                    return showErrorToast('No claims report data found for provided range')
                }
                const claimsReportData = response.data.data

                const headers = [
                    ["Claims Generated Report"],
                    [],
                    ["Report Date", claimsReportData.report_date],
                    ["Generated By", claimsReportData.generated_by],
                    ["Report Start Date", claimsReportData.start_date],
                    ["Report End Date", claimsReportData.end_date],
                    [],
                    ["# of Claims Created", claimsReportData.number_of_claims],
                    // ["Total Charges for this period", claimsReportData.total_charges],
                    [],
                    [
                        "Date of Claim",
                        "Claim #",
                        "Policy #",
                        "Policy Type",
                        "First Name",
                        "Last Name",
                        "Device Model",
                        "Device IMEI",
                        "Type of Claim",
                        "Status",
                        "Order #",
                        // "Amount Charged",
                    ],
                ]

                // Format the claims data
                const formattedClaims = claimsReportData.claims.map((claim: any) => [
                    claim.created_at,
                    claim.claim_id,
                    claim.policy_number,
                    claim.policy_type,
                    claim.customer_firstname,
                    claim.customer_lastname,
                    claim.device,
                    claim.imei.toString(),
                    claim.claim_type,
                    claim.status,
                    claim.order_number,
                    // claim.amount_charged,
                ])

                // Combine metadata and claims data
                const csvData = [...headers, ...formattedClaims]
                setCsvData(csvData)
                setIsLoading(false)
                showSuccessToast()
            }).catch(err => {
                // handleRateLimit(err)
                setIsLoading(false)
                showErrorToast()
            })
        } else {
            setIsLoading(false)
        }
    }, [startDate, endtDate, reportType, validateDates, setIsLoading, setCsvData])

    const generateReportDisabled = !startDate || !endtDate || !reportType || Object.keys(dateErrors).length > 0

    return (
        <div className='reports-container'>
            <Breadcrumbs lastNodeText={title} />

            <div style={{ paddingLeft: '48px', paddingRight: '48px', paddingTop: '48px' }}>
                <ContentHeader title={title} actions={contentHeaderActions} subHeader={contentSubHeader} />

                <ReportArea
                    setFromDate={setStartDate}
                    setToDate={setEndDate}
                    fromDate={startDate ?? new Date()}
                    toDate={endtDate ?? new Date}
                    reportType={reportType}
                    setReportType={setReportType}
                    dateErrors={dateErrors}
                />
            </div>

            {
                isLoading
                    ?
                    <div className='loading-container'>
                        <LoadingSpinner message='Generating report' />
                    </div>
                    :
                    <div style={{ paddingLeft: '48px', paddingRight: '48px', paddingTop: '48px', flex: 1 }}>
                        <GeneratedReport data={csvData} loading={isLoading} />
                    </div>
            }

            <ReportFooter disabled={generateReportDisabled} onClick={generateReportData} />

        </div>
    )

}


export default Reports