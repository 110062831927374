import axios from "axios";
import { network } from "../../services";

const getAllClaims = async (page: number, limit: number, search: string, sortBy: string, filter: string, bearerToken: string, order: string) => {
    const response = await axios.get(`${network.baseApi}/claims/all?page=${page}&limit=${limit}&search=${search}&sortBy=${sortBy}&filter=${filter}&order=${order}`, { headers: { Authorization: bearerToken } })
    return response
}

const generateReportData = async(fromDate: Date, toDate: Date, generatedByUser: string, bearerToken: string) => {
    const response = await axios.get(`${network.baseApi}/claims/generateReport`, {
        params: { startDate: fromDate, endDate: toDate, generatedBy: generatedByUser },
        headers: { Authorization: bearerToken },
    })
    return response
}

const ClaimService = {
    getAllClaims,
    generateReportData
}

export default ClaimService