import React, { CSSProperties, ReactNode, useCallback, useContext } from 'react'

import { DropdownIcon } from '../../assets/svg/pageHeader/DropdownIcon';
import { ReactComponent as Ascending } from '../../assets/svg/claims/asc.svg'
import { ReactComponent as Descending } from '../../assets/svg/claims/desc.svg'
import { stopClickPropagation } from '../../utils/propagationUtil';
import Button from '../Button/Button';

import './Dropdown.css';
import { Tooltip } from '../Tooltip/Tooltip';
import ClaimContext from '../../contexts/Claim/ClaimContext';

export interface Option {
  id: number;
  text: string;
  classes?: string,
  selected: boolean;
  onClick: VoidFunction;
}

/**
 * The type for the dropdown component. Only one of either
 * the `options` or `children` should be passed to a Dropdown
 * instance.
 */
export interface IDropdown {
  text: ReactNode;
  visible: boolean;
  toggleVisibility: VoidFunction;
  className?: string;
  hideArrowIcon?: boolean;
  options?: Option[];
  testId?: string;
  styles?: CSSProperties;
  optionStyles?: CSSProperties;
  dropdownAreaStyles?: CSSProperties;
  dropdownTextStyles?: CSSProperties;
  children?: ReactNode;
  toggle?: boolean
  handleToggle?: () => void
  order?: string
}

const Dropdown = ({ text, visible, className, hideArrowIcon = false, options, styles = {}, dropdownAreaStyles = {}, dropdownTextStyles = {}, optionStyles = {}, testId = 'dropdown', children, toggleVisibility, toggle = false, order, handleToggle}: IDropdown) => {
  return (
    <div className={`dropdown ${className}`} data-testid={testId} onClick={(e) => stopClickPropagation(e, toggleVisibility)} style={{ ...styles }}>
      <p className='dropdown-text' style={{ ...dropdownTextStyles }}>{text}</p>
      {!hideArrowIcon && <DropdownIcon strokeColor='#222426' visible={visible} />}
      {
        toggle
        ?
        <Tooltip content={`Sort ${order === 'asc' ? "Ascending" : "Descending"}`}>
          <button className='toggle' onClick={(e) => stopClickPropagation(e, handleToggle)}>
            {
              order === 'asc'
              ?
              <Ascending style={{width: '20px'}}/>
              :
              <Descending style={{width: '20px'}}/>
            }
          </button>
        </Tooltip>
        :
        null
      }

      {/* ---------- Dropdown area ---------- */}
      {visible &&
        <div className="dropdown-area" style={dropdownAreaStyles}>
          {/* ---------- Option buttons ---------- */}
          {options
            ? options.map(option => (
              <Button
                classes={`${option.classes || ''} ${option.selected ? 'selected' : ''}`}
                text={option.text}
                onClick={option.onClick}
                styles={optionStyles}
              />
            ))

            : children
          }
        </div>
      }
    </div>
  )
}

export default Dropdown