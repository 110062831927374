import { useCallback, useContext, useEffect, useState } from 'react'
import ContentControls from '../../components/ContentControls/ContentControls'
import ContentHeader from '../../components/ContentHeader/ContentHeader'
import { IDropdown } from '../../components/Dropdown/Dropdown'
import './AuditLog.css'
import AuditContext from '../../contexts/Audit/AuditContext'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import PlaceholderContent from '../../components/PlaceholderContent/PlaceholderContent'
import { Table } from '../../components/Content/AuditLog/Table'
import { auditTableHeadings } from '../../utils/auditUtils'
import Footer from '../../components/Footer/Footer'
import AuditLogDetails from './AuditLogDetails/AuditLogDetails'

const AuditLog = () => {
  const { data, isLoading, sortBy, setSortBy, itemsPerPage, setItemsPerPage, getAuditLogs, order, setSortOrderBy } = useContext(AuditContext);
  const [isItemsPerPageVisible, setIsItemsPerPageVisible] = useState<boolean>(false);
  const [isSortVisible, setIsSortVisible] = useState<boolean>(false);
  const [auditDetailsVisible, setAuditDetailsVisible] = useState<boolean>(false);
  const [selectedAudit, setSelectedAudit] = useState<any>(null);

  const toggleAuditDetails = (data?: any) => {
    if (data) {
      setSelectedAudit(data);
    }
    // if (!auditDetailsVisible) setSelectedAudit(null)
    setAuditDetailsVisible(!auditDetailsVisible)
  }

  const contentHeaderActions: JSX.Element[] = []
  const contentInfoArray: JSX.Element[] = []

  const auditItemsPerPageProps: IDropdown = {
    text: `${itemsPerPage} items per page`,
    visible: isItemsPerPageVisible,
    toggleVisibility: () => setIsItemsPerPageVisible(!isItemsPerPageVisible),
    options: [
      {
        id: 10,
        text: '10 items per page',
        selected: itemsPerPage === 10,
        onClick: () => setItemsPerPage(10)
      },
      {
        id: 25,
        text: '25 items per page',
        selected: itemsPerPage === 25,
        onClick: () => setItemsPerPage(25)
      },
      {
        id: 50,
        text: '50 items per page',
        selected: itemsPerPage === 50,
        onClick: () => setItemsPerPage(50)
      }
    ]
  }

  const auditSortProps: IDropdown = {
    text: `Sort By`,
    visible: isSortVisible,
    toggleVisibility: () => setIsSortVisible(!isSortVisible),
    options: [
      {
        id: 1,
        text: 'Type',
        selected: sortBy === 'type',
        onClick: () => setSortBy('type'),
      },
      {
        id: 2,
        text: 'Action',
        selected: sortBy === 'action',
        onClick: () => setSortBy('action'),
      },
      {
        id: 4,
        text: 'Timestamp',
        selected: sortBy === 'timestamp',
        onClick: () => setSortBy('timestamp'),
      }
    ]

  }

  const auditFilterProps: IDropdown = {
    text: 'Filter',
    visible: false,
    toggleVisibility: () => { },
  }

  const handleToggleClick = useCallback(() => {
    const newOrder = order === 'asc' ? 'desc' : 'asc';
    setSortOrderBy(newOrder)
  }, [order, setSortOrderBy])

  useEffect(() => {
    getAuditLogs(1)
  }, [itemsPerPage, sortBy, order])

  return <>

    {
      (data.auditData !== undefined && data.auditData.length > 0) &&
      <div style={{ paddingLeft: '48px', paddingRight: '48px', paddingTop: '48px' }}>
        <ContentHeader
          title='Audit Log'
          actions={[]}
        />

        <ContentControls
          contentInfoLabel={<p><strong>{data.meta.total}</strong> Logs</p>}
          contentInfoArray={contentInfoArray}
          itemsPerPageProps={auditItemsPerPageProps}
          sortProps={auditSortProps}
          filterProps={auditFilterProps}
          orderControl
          handleOrderControl={handleToggleClick}
          order={order}
        />
      </div>
    }

    {
      (!isLoading && (data.auditData !== undefined && data.auditData.length === 0)) &&
      <>
        <div style={{ paddingLeft: '48px', paddingRight: '48px', paddingTop: '48px' }}>
          <ContentHeader title='Audit Log' actions={contentHeaderActions} />
          <ContentControls
            data-testid="custom-element"
            contentInfoLabel={<p data-testid="total-claims-value"><strong>{data.meta.total || 0}</strong> Logs</p>}
            contentInfoArray={contentInfoArray}
            itemsPerPageProps={auditItemsPerPageProps}
            sortProps={auditSortProps}
            filterProps={auditFilterProps}
            orderControl={false} />
        </div>
        <PlaceholderContent text='Sorry. The audits could not be found.' />
      </>
    }

    {
      (isLoading) &&
      <LoadingSpinner message='Just a moment. Retrieving audit log .' />
    }

    <div className='policy-container'>
      {
        auditDetailsVisible
        ?
        <AuditLogDetails toggle={toggleAuditDetails} data={selectedAudit}/>
        : null
      }
      {
        isLoading &&
        <LoadingSpinner message='Just a moment. Retrieving all audit logs.' />
      }

      {
        (!isLoading && data.auditData !== undefined && data.auditData.length > 0) &&
        <div>
          <Table headers={auditTableHeadings} data={data.auditData || []} toggle={toggleAuditDetails}/>
        </div>
      }

      {
        (data.auditData !== undefined && data.auditData.length > 0) &&
        <div style={{ paddingLeft: '48px', paddingRight: '48px', paddingTop: '48px' }}>
          <Footer data={data.meta || {}} itemsPerPage={itemsPerPage} onPageChange={getAuditLogs} />
        </div>
      }
    </div>

  </>
}

export default AuditLog