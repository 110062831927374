import { View, Text, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    sectionHeader: { width: '90%', height: 25, borderRadius: 5, backgroundColor: '#ecf0f2', marginRight: 30, padding: 4, justifyContent: 'center' },
    sectionHeaderText: {fontSize: 12, fontWeight: 'black' },
})

const ClaimReceiptSectionHeader = ({ heading, customStyles }: { heading: string, customStyles?: {} }) =>
(
    <View style={[styles.sectionHeader, customStyles ? customStyles : {}]}>
        <Text style={styles.sectionHeaderText}>{heading}</Text>
    </View>
)

export default ClaimReceiptSectionHeader