import { createContext, useCallback, useContext, useEffect, useReducer, useState } from "react"
import AuditService from "../../hooks/api/AuditService";
import { createAction } from "../../utils/reducerUtils";
import { useAuthState } from "../Auth/context";
import { capitalizeFirstLetter } from "../../utils/stringUtils";
import { handleRateLimit } from "../../utils/rateLimitUtil";
import SearchContext from "../Search/SearchContext";
import { AUDIT_ACTION_TYPES, IAuditData, Meta } from "./auditTypes";

const AUDITS_INITIAL_STATE = {
  data: {
    meta: {} as Meta,
    auditData: []
  },
  isLoading: false,
  sortBy: 'id',
  setSortBy: () => { },
  filterBy: undefined,
  setFilterBy: (filterBy: string) => { },
  itemsPerPage: 10,
  order: 'desc',
  setSortOrderBy: (order: string) => { },
  setItemsPerPage: () => { },
  getAuditLogs: (page: number) => { }
}

const auditLocalStorageNames = {
  'auditSort': 'auditSort',
  'auditFilter': 'auditFilter',
  'auditItemsPerPage': 'auditItemsPerPage',
  'auditOrder': 'auditOrder'
}

const AuditContext = createContext<IAuditData>(AUDITS_INITIAL_STATE);

const auditReducer = (state: any, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case AUDIT_ACTION_TYPES.SET_AUDIT_DATA:
      return {
        ...state,
        data: payload
      }
    case AUDIT_ACTION_TYPES.SET_AUDIT_SORT_BY:
      return {
        ...state,
        sortBy: payload
      }
    case AUDIT_ACTION_TYPES.SET_AUDIT_FILTER_BY:
      return {
        ...state,
        filterBy: payload
      }
    case AUDIT_ACTION_TYPES.SET_AUDIT_ITEMS_PER_PAGE:
      return {
        ...state,
        itemsPerPage: payload
      }
    case AUDIT_ACTION_TYPES.SET_AUDIT_ORDER_TYPE:
      return {
        ...state,
        order: payload
      }

    default:
      throw new Error(`Unhandled type ${type} in userReducer.`);
  }
}

export const AuditProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(auditReducer, AUDITS_INITIAL_STATE);
  const { searchClaimQuery } = useContext(SearchContext)
  const { token } = useAuthState();
  const [isLoading, setIsLoading] = useState(false)

  const { data, sortBy, filterBy, itemsPerPage, order } = state;

  useEffect(() => {
    const sort = localStorage.getItem(auditLocalStorageNames.auditSort || '');
    const filter = localStorage.getItem(auditLocalStorageNames.auditFilter) || '';
    const order = localStorage.getItem(auditLocalStorageNames.auditOrder || '');
    const ipp = Number.parseInt(localStorage.getItem(auditLocalStorageNames.auditItemsPerPage) || '10');

    dispatch(createAction(AUDIT_ACTION_TYPES.SET_AUDIT_SORT_BY, sort));
    dispatch(createAction(AUDIT_ACTION_TYPES.SET_AUDIT_FILTER_BY, filter));
    dispatch(createAction(AUDIT_ACTION_TYPES.SET_AUDIT_ITEMS_PER_PAGE, ipp));
    dispatch(createAction(AUDIT_ACTION_TYPES.SET_AUDIT_ORDER_TYPE, order));
  }, []);

  const getAuditLogs = useCallback((page: number) => {

    // if (sortBy && itemsPerPage && order) {
    setIsLoading(true)
    const bearerToken = `${capitalizeFirstLetter(token.type)} ${token.token}`;

    AuditService.getAuditLogs(page, itemsPerPage, sortBy, bearerToken, order).then(response => {
      const audits = response.data;

      setAuditData(audits);
      // setSortBy(sortBy)
      setIsLoading(false)
    }).catch(err => {
      handleRateLimit(err)
      setIsLoading(false)
    })
    // }
  }, [sortBy, itemsPerPage, order, token])

  const setAuditData = (data: any) => {
    dispatch(createAction(AUDIT_ACTION_TYPES.SET_AUDIT_DATA, data))
  }

  const setSortBy = (sortBy: string) => {
    localStorage.setItem(auditLocalStorageNames.auditSort, sortBy)
    dispatch(createAction(AUDIT_ACTION_TYPES.SET_AUDIT_SORT_BY, sortBy))
  }

  const setFilterBy = (filterBy: string) => {
    localStorage.setItem(auditLocalStorageNames.auditFilter, filterBy)
    dispatch(createAction(AUDIT_ACTION_TYPES.SET_AUDIT_FILTER_BY, filterBy))
  }

  const setItemsPerPage = (id: number) => {
    localStorage.setItem(auditLocalStorageNames.auditItemsPerPage, id.toString())
    dispatch(createAction(AUDIT_ACTION_TYPES.SET_AUDIT_ITEMS_PER_PAGE, id))
  }

  const setSortOrderBy = (order: string) => {
    localStorage.setItem(auditLocalStorageNames.auditOrder, order)
    dispatch(createAction(AUDIT_ACTION_TYPES.SET_AUDIT_ORDER_TYPE, order))
  }

  return (
    <AuditContext.Provider value={{ data, isLoading, sortBy, setSortBy, filterBy, setFilterBy, itemsPerPage, setItemsPerPage, getAuditLogs, setSortOrderBy, order }}>
      {children}
    </AuditContext.Provider>
  )
}

export default AuditContext