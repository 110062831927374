import React, { useState } from 'react';
import './AuditLogDetails.css';   
import CloseIcon from '../../../assets/svg/CloseIcon';
import AuditLogHeader from './components/AuditLogHeader';
import AuditLogRowDetail from './components/AuditLogRowDetail';
import { formatAuditDate } from '../../../utils/dateUtil';
import { capitalizeEachFirstLetter } from '../../../utils/stringUtils';

const AuditLogDetails = ({toggle, data}: {toggle: () => void, data: any}) => {

    return (
        <div className='audit-modal-background'>
            <div className='audit__container'>
                <div className='audit-content__container'>
                    <AuditLogHeader 
                        label='Details'
                        icon={ <CloseIcon /> }
                        onIconClick={toggle}
                    />
                    <table className='audit-table'>
                        <tbody>
                            <AuditLogRowDetail field='Application' description='Smart Protect Application' />
                            <AuditLogRowDetail field='Performed By' description={data.performed_by} />
                            <AuditLogRowDetail field='Performed On' description={data.performed_on} />
                            <AuditLogRowDetail field='User Type' description={capitalizeEachFirstLetter(data.user_role)} />
                            <AuditLogRowDetail field='Timestamp' description={formatAuditDate(data.created_at)} />
                            <AuditLogRowDetail field='Action' description={data.event_details} />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default AuditLogDetails;