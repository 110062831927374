import React, { useState } from 'react';
import './index.css';   

const AuditLogHeader = ({ label, icon, onIconClick }: { label: string; icon: React.ReactNode, onIconClick?: () => void }) => {
    return (
        <div className='audit-header-container'>
            <h1>{label}</h1>
            {
                onIconClick
                ? (
                    <button onClick={onIconClick}>
                        {icon}
                    </button>
                )
                : (
                    <span>
                        {icon}
                    </span>
                )
            }
        </div>
    );
};

export default AuditLogHeader;