import { ReactNode } from 'react';

import './content-header.css';

interface IContentHeader {
  title: string;
  actions?: ReactNode[];
  subHeader?: string
}

const ContentHeader = ({ title, actions = [], subHeader }: IContentHeader) => {

  return (
    <div className='content-header-container'>
      <div className='content-header'>
        <p>{title}</p>
        <div>
          {
            actions.map(action => action)
          }
        </div>
      </div>

      {
        subHeader ? <p>{subHeader}</p> : null
      }
    </div>
  )
}

export default ContentHeader