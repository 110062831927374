import { useState, useRef, ChangeEvent, useCallback } from "react";
import { ClaimStatuses } from "../../constants";
import { DocumentData } from "../../services/claims/claimService";
import { timeAgo } from "../../utils/dateUtil";
import { convertToBase64 } from "../../utils/stringUtils";
import Button from "../Button/Button";
import { ReactComponent as Check } from '../../assets/svg/check.svg'
import { ReactComponent as Upload } from '../../assets/svg/claims/paperclip.svg';
import { ReactComponent as Document } from '../../assets/svg/claims/files.svg';
import { ICommentsProps } from "./Comments-types";
import './Comments.css'

const Comments = ({ data: { claim }, commentsData, addComment }: ICommentsProps) => {
  const [content, setContent] = useState('')
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [rejectedFiles, setRejectedFiles] = useState({
    name: "",
    count: 0,
  });
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setContent(event.target.value);
  }

  const onButtonClick = (e: any) => {
    inputRef.current!.click();
  };

  const generateDocuments = useCallback(async () => {
    let base64StringFiles: DocumentData[] = []
    await Promise.all(uploadedFiles.map(async (file: File) => {
      const base64String = await convertToBase64(file)
      const documentData: DocumentData = {
        name: file.name,
        file: base64String,
      }
      base64StringFiles.push(documentData);
    }))

    return base64StringFiles
  }, [uploadedFiles])

  const submitComment = async () => {
    const documents = await generateDocuments()

    addComment(content, documents)
    setContent('')
    setUploadedFiles([])
  }

  const handleDocumentChange = function (e: any) {
    e.preventDefault();
    const newFiles = [...e.target.files];
    let newFiles2 = [...e.target.files];

    handleFiles(newFiles, newFiles2)
  };

  const handleFiles = function (newFiles: any, newFiles2: any) {
    let foundFileOverLimit = false;
    let rejectedFiles: string[] = [];

    newFiles.forEach(async (file: File) => {
      if (file.size > 20971520) {
        rejectedFiles.push(file.name)
        foundFileOverLimit = true;
        newFiles2 = newFiles2.filter((f: any) => f !== file)
      }
    })

    setRejectedFiles({ name: rejectedFiles[0], count: rejectedFiles.length - 1 });
    if (newFiles2.length > 0) {
      setUploadedFiles([...uploadedFiles, ...newFiles2])
    }

    if (foundFileOverLimit) {
      // setOpenUploadFileModal(true)
    }
  }

  return (
    <div className='comments-wrapper'>

      <div className='comments-section'>
        <p>Comments</p>
      </div>

      <div className='comments__details'>
        <div className='comment-status'>
          {
            ClaimStatuses.map((status, index) => {
              const statusFound = ClaimStatuses.find(status => status.toLowerCase().includes(claim.status.toLowerCase())) || status
              const currentStatus = statusFound.toLowerCase() === status.toLowerCase()
              const isNotSelectable = index > ClaimStatuses.indexOf(statusFound.toUpperCase())
              const previousStatusUpdate = index < ClaimStatuses.indexOf(statusFound.toUpperCase())
              const pastUpdates = previousStatusUpdate ? { backgroundColor: 'white' } : {}
              
              return (
                <span
                  key={ index }
                  className={`status-label ${currentStatus ? 'active' : ''}`}
                  style={{ 
                    ...pastUpdates, 
                    ...(isNotSelectable ? { cursor: 'not-allowed', color: '#ddd' } : {}) 
                  }}
                >
                  <p>
                    {
                      currentStatus ? <Check /> : null
                    }
                    { status }
                  </p>
                </span>
              )
            })
          }
        </div>

        <div className='claim-comments'>
          {/* Add Comment Component */}
          <div className="comments">
            {
              commentsData && commentsData.length > 0
                ?
                commentsData.map(comment => (
                  <div className='comment-container'>
                    <div className='comment-header'>
                      <h4 className='commenter'>{ comment.username }</h4>

                      <h4 className='comment-time'>{ timeAgo(comment.created_at) }</h4>
                      |
                      <h4 className='current-status'>{ comment.current_status }</h4>
                    </div>
                    
                    <div className="comment-body">
                      <p>{ comment.content }</p>
                    </div>
                  </div>
                ))
                :
                null
            }
          </div>
          <div className='add-comment'>
            <textarea
              value={content}
              onChange={handleChange}
              className="user-comment"
              maxLength={160}></textarea>

            <hr className='comment-action-seperator' />

            <div className='comment-actions'>
              <div className='uploaded-docs'>
                {
                  uploadedFiles.map((file: File, index: number) => (
                    <div className='document-item'>
                      <Document />
                      <p className='document-info'>{file.name} <span>{`(${(file.size / 1048576).toFixed(2)}mb)`}</span></p>
                    </div>
                  ))
                }
              </div>

              <div className='buttons-group'>
                <input 
                  ref={ inputRef } 
                  id="input-file-upload" 
                  type="file" 
                  multiple={ true } 
                  onChange={ handleDocumentChange } 
                  accept=".pdf,.doc,.jpeg,.png" />
                <Button
                  text={ <Upload /> }
                  onClick={ onButtonClick }
                  classes='icon-btn'
                />

                <Button
                  text={'Add Comment'}
                  onClick={content.length > 3 ? submitComment : null}
                  classes={content.length > 3 ? 'add-comment-btn' : ''}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Comments