import { useCallback, useContext, useEffect, useState } from 'react'
import { IDropdown } from '../../components/Dropdown/Dropdown'
import { Table } from '../../components/Content/Claims/Table/Table'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import PlaceholderContent from '../../components/PlaceholderContent/PlaceholderContent'
import ContentControls from '../../components/ContentControls/ContentControls'
import ContentHeader from '../../components/ContentHeader/ContentHeader'
import ClaimContext from '../../contexts/Claim/ClaimContext'
import SearchContext from '../../contexts/Search/SearchContext'
import Footer from '../../components/Footer/Footer'

import './AllClaims.css'
import { claimTableHeadings, sortOptions } from '../../utils/claimUtil'
import Button from '../../components/Button/Button'

const AllClaims = () => {

  const { data, isLoading, sortBy, setSortBy, filterBy, itemsPerPage, setItemsPerPage, getAllClaims, order, setSortOrderBy } = useContext(ClaimContext);
  const { setSearchQuery } = useContext(SearchContext)

  const [isItemsPerPageVisible, setIsItemsPerPageVisible] = useState<boolean>(false);
  const [isSortVisible, setIsSortVisible] = useState<boolean>(false);
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);

  useEffect(() => {
    getAllClaims(1)

    return () => {
      setSearchQuery('any', '')
    }
  }, [itemsPerPage, sortBy, filterBy, order])

  const claimItemsPerPageProps: IDropdown = {
    text: `${itemsPerPage} items per page`,
    visible: isItemsPerPageVisible,
    toggleVisibility: () => setIsItemsPerPageVisible(!isItemsPerPageVisible),
    options: [
      {
        id: 5,
        text: '5 items per page',
        selected: itemsPerPage === 5,
        onClick: () => setItemsPerPage(5)
      },
      {
        id: 10,
        text: '10 items per page',
        selected: itemsPerPage === 10,
        onClick: () => setItemsPerPage(10)
      },
      {
        id: 25,
        text: '25 items per page',
        selected: itemsPerPage === 25,
        onClick: () => setItemsPerPage(25)
      },
      {
        id: 50,
        text: '50 items per page',
        selected: itemsPerPage === 50,
        onClick: () => setItemsPerPage(50)
      }
    ]
  }

  const claimSortProps: IDropdown = {
    text: `Sort By ${sortOptions[sortBy as keyof typeof sortOptions] || 'Details'}`,
    visible: isSortVisible,
    toggleVisibility: () => setIsSortVisible(!isSortVisible),
    options: [
      {
        id: 1,
        text: 'Type',
        selected: sortBy === 'type',
        onClick: () => setSortBy('type'),
      },
      {
        id: 2,
        text: 'Status',
        selected: sortBy === 'status',
        onClick: () => setSortBy('status'),
      },
      {
        id: 4,
        text: 'Policy',
        selected: sortBy === 'policyNumber',
        onClick: () => setSortBy('policyNumber'),
      },
      {
        id: 5,
        text: 'Date',
        selected: sortBy === 'date',
        onClick: () => setSortBy('date'),
      },
      {
        id: 6,
        text: 'First Name',
        selected: sortBy === 'fname',
        onClick: () => setSortBy('fname'),
      },
      {
        id: 7,
        text: 'Last Name',
        selected: sortBy === 'lname',
        onClick: () => setSortBy('lname'),
      },
      {
        id: 8,
        text: 'Claim Number',
        selected: sortBy === 'claimNumber',
        onClick: () => setSortBy('claimNumber'),
      },
    ]
  }

  const claimFilterProps: IDropdown = {
    text: 'Filter',
    visible: isFilterVisible,
    toggleVisibility: () => setIsFilterVisible(!isFilterVisible),
    // options: [
    //   {
    //     id: 1,
    //     text: 'Open',
    //     selected: filterBy === 'open',
    //     onClick: () => setFilterBy('open'),
    //   },
    //   {
    //     id: 2,
    //     text: 'Closed',
    //     selected: filterBy === 'closed',
    //     onClick: () => setFilterBy('closed'),
    //   },
    // ]
  }

  const contentHeaderActions: JSX.Element[] = [
    // <Button classes='p-btn' text='View Archive' onClick={() => { }} />,
    <Button classes='p-btn sms-btn' text='Generate Report' onClick={() => { }} />
  ]

  const contentInfoArray: JSX.Element[] = [
    <p><strong>{data.claimData.filter(claim => claim.status.toLowerCase() !== 'close').length}</strong> Open</p>,
    <p><strong>{data.claimData.filter(claim => claim.status.toLowerCase() === 'close').length}</strong> Closed</p>
  ]
  
  const handleToggleClick = useCallback(() => {
    const newOrder = order === 'asc' ? 'desc' : 'asc';
    setSortOrderBy(newOrder)
  }, [order, setSortOrderBy])

  return <>
    {
      (data.claimData !== undefined && data.claimData.length > 0) &&
      <div style={{ paddingLeft: '48px', paddingRight: '48px', paddingTop: '48px' }}>
        <ContentHeader title='Claims' actions={contentHeaderActions} />
        <ContentControls
          data-testid="custom-element"
          contentInfoLabel={<p data-testid="total-claims-value"><strong>{data.meta.total}</strong> Total Claims</p>}
          contentInfoArray={contentInfoArray}
          itemsPerPageProps={claimItemsPerPageProps}
          sortProps={claimSortProps}
          filterProps={claimFilterProps}
          orderControl
          handleOrderControl={handleToggleClick}
          order={order}
        />
      </div>
    }

    {
      (!isLoading && (data.claimData !== undefined && data.claimData.length === 0)) &&
      <>
        <div style={{ paddingLeft: '48px', paddingRight: '48px', paddingTop: '48px' }}>
          <ContentHeader title='Claims' actions={contentHeaderActions} />
          <ContentControls
            data-testid="custom-element"
            contentInfoLabel={<p data-testid="total-claims-value"><strong>{data.meta.total}</strong> Total Claims</p>}
            contentInfoArray={contentInfoArray}
            itemsPerPageProps={claimItemsPerPageProps}
            sortProps={claimSortProps}
            filterProps={claimFilterProps}
            orderControl={false} />
        </div>
        <PlaceholderContent text='Sorry. The claims could not be found.' />
      </>
    }


    <div className='policy-container'>
      {
        isLoading &&
        <LoadingSpinner message='Just a moment. Retrieving all claims.' />
      }

      {
        (!isLoading && data.claimData !== undefined && data.claimData.length > 0) &&
        <div>
          <Table headers={claimTableHeadings} data={data.claimData || []} />
        </div>
      }

      {
        (data.claimData !== undefined && data.claimData.length > 0) &&
        <div style={{ paddingLeft: '48px', paddingRight: '48px', paddingTop: '48px' }}>
          <Footer data={data.meta || {}} itemsPerPage={itemsPerPage} onPageChange={getAllClaims} />
        </div>
      }
    </div>
  </>

}

export default AllClaims